import { DocumentTextIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useCallback } from "react";
import { useDropzone } from 'react-dropzone';

const FileDropzone = ({ files, onUpload }) => {
    const onDrop = useCallback(acceptedFiles => {
        onUpload([...files, ...acceptedFiles]);
    }, [files, onUpload]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: true });

    const removeFile = (fileToRemove) => {
        const updatedFiles = files.filter(file => file !== fileToRemove);
        onUpload(updatedFiles);
    };

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="flex flex-col items-center px-4 py-10 space-y-4 border border-dashed rounded-md cursor-pointer">
                {files.length > 0 && (
                    <div className="w-full space-y-2">
                        {files.map((file, index) => (
                            <div key={index} className="flex items-center justify-between p-2 border-b">
                                <div className="flex items-center space-x-2">
                                    <DocumentTextIcon width={24} className="text-slate-600" />
                                    <span className="text-slate-600 text-md">{file.name}</span>
                                </div>
                                <XMarkIcon
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        removeFile(file);
                                    }}
                                    width={22}
                                    className="text-orange-700 cursor-pointer"
                                />
                            </div>
                        ))}
                    </div>
                )}
                <div className="text-gray-600">
                    {isDragActive 
                        ? <p>Drop the files here ...</p>
                        : <p>Drag 'n' drop or click to select files</p>
                    }
                </div>
            </div>
        </div>
    );
}

export default FileDropzone;