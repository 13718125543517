import React from 'react';
import ElevationLogo from '../icons/ElevationLogo';

const PublicLayout = ({ children }) => {
    return (
        <div className="flex flex-col w-screen h-screen p-5 md:flex-row md:p-8 min-h-[640px]">
            <div 
                className="relative w-full md:w-1/2 h-1/2 md:h-full  bg-[#064857] rounded-[31px] md:rounded-[61px] bg-gradient-to-t from-[#096175]/60 to-[#096175]/40 bg-blend-multiply bg-no-repeat bg-cover bg-center p-6" 
                style={{ backgroundImage: "url('/alternate-landscape.png')" }}
            >
                <div className="absolute top-12 left-12">
                    <ElevationLogo />
                </div>
                <div className="absolute space-y-6 bottom-10 left-8 right-8">
                    <div className="space-y-2 text-left">
                        <h2 className="font-sans text-2xl font-normal text-white sm:text-5xl">
                            Dinaric Elevation
                        </h2>
                        <p className="font-sans text-base font-light text-white sm:text-lg lg:text-xl">
                            Rapid Prototyping and Evaluation of AI Workflows
                        </p>
                    </div>
                    <div className="w-full h-2 px-3 bg-white rounded-full "></div>
                </div>
            </div>
            <div className="flex items-center justify-center w-full md:w-1/2 h-1/2 md:h-full">
                {children}
            </div>
        </div>
    );
};

export default PublicLayout;