import { useContext } from "react";
import { AuthContext } from "../store/AuthContext";

const ProtectedElement = ({ children, roles }) => {
    const authContext = useContext(AuthContext);

    if (!authContext.session || (roles && !roles.includes(authContext.session.role))) {
        return null;
    }

    return children;
}

export default ProtectedElement;