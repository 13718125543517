import SidebarLayout from "../../components/layouts/SidebarLayout";
import FileDropzone from "../../components/upload/FileDropzone";
import { useState } from "react";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { isSuccess } from "../../shared/util";
import axiosClient from "../../shared/axiosClient";
import { toast } from "react-toastify";
import { AuthContext } from "../../store/AuthContext";
import { CopyBlock } from 'react-code-blocks';
import { useContext } from "react";

const Rag = () => {
    const [files, setFiles] = useState([]);


    const [formSubmissionInProgress, setformSubmissionInProgress] = useState(false);
    const [errorMessages, setErrorMessages] = useState(null);
    const [error, setError] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL
    const authContext = useContext(AuthContext);
    const code = `
        <script>
        let company_uuid = "${authContext.companyUuid}"
        let endpoint_url = "${apiUrl}/rag"
        </script>
        
        <script src="https://storage.dinaric.io/scripts/chat-widget.js"></script>
        `;


    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(code)
            .then(() => {
                toast.success('Copied to clipboard!', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: null,
                    theme: "light",
                });
            })
            .catch((err) => {
                toast.error('An error occured!', {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: null,
                    theme: "light",
                });
            });
    };


    const handleFormSubmission = async () => {
        setformSubmissionInProgress(true);
        setErrorMessages(null);
        setError(false);

        if (files.length === 0) {
            setErrorMessages('Files are required')
            setformSubmissionInProgress(false)
            return
        }

        let formData = new FormData();
        files.forEach((file) => {
            formData.append(`files`, file);
        });

        let fileUploadResponse = null
        try {
            fileUploadResponse = await axiosClient.post("file/", formData, {
                headers: { "Content-type": "multipart/form-data" }
            });
        } catch (error) {
            setError(true)
            setformSubmissionInProgress(false);
            return
        }


        if (!isSuccess(fileUploadResponse.status)) {
            const errorMessage = fileUploadResponse.data && fileUploadResponse.data.message ? fileUploadResponse.data.message
                : 'Something went wrong. Please contact system administrator.';

            setError(true)
            setErrorMessages(errorMessage);
            setformSubmissionInProgress(false);
            return;
        }

        toast.success('Files Uploaded', {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: null,
            theme: "light",
        });
        setFiles([])
        setformSubmissionInProgress(false);
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <SidebarLayout title="RAG">
            <div className="flex flex-col space-y-10 max-w-[720px]">
                <div className="font-sans">
                    <p className="text-lg font-semibold">Add the chatbot to your website</p>
                    <ol className="flex flex-col mt-4 ml-4 space-y-4 list-decimal">
                        <li>
                            <span className="font-medium">Upload Files:</span ><span className="font-normal"> Drag and drop your files (PDF, DOCX, TXT) into the uploader. Once the files are processed, you will receive a code snippet.</span >
                        </li>
                        <li>
                            <span className="font-medium">Copy the Integration Code:</span><span className="font-normal">After uploading, copy the provided code snippet.</span >
                        </li>
                        <li>
                            <span className="font-medium">Insert the Code on Your Website:</span><span className="font-normal"> Open your website’s HTML editor. Paste the code snippet before the closing <code>&lt;/body&gt;</code> tag on the pages where you want the chatbot to appear.</span >
                        </li>
                        <li>
                            <span className="font-medium">Save and Publish:</span><span className="font-normal"> Save the changes and publish your website. The chatbot will now be live and ready to assist your visitors.</span >
                        </li>
                    </ol>
                </div>
                <form className="flex flex-col space-y-4 font-sans text-center md:text-start" onSubmit={onFormSubmit}>
                    <div className="flex flex-col space-y-6">
                        <FileDropzone
                            files={files}
                            onUpload={(files) => setFiles(files)}
                        />
                    </div>
                    <div className="w-full md:w-[410px]">
                        <PrimaryButton
                            text="Submit"
                            spinning={formSubmissionInProgress}
                            onClick={handleFormSubmission}
                            type="submit"
                            bgColor="bg-dinaric-button"
                            className={"w-1/2"}
                        />
                    </div>
                    <div className="flex flex-col justify-center space-y-1">
                        {error && <span className="text-red-600">Error uploading files</span>}
                        {errorMessages && <span className="text-red-600">{errorMessages}</span>}
                    </div>
                </form>
                <div className="flex flex-col space-y-4">
                    <div className="w-full overflow-x-auto copy-block-container rounded-lg">
                        <CopyBlock
                            text={code}
                            language="javascript"
                            showLineNumbers={false}
                            customStyle={{backgroundColor: '#DDF0F4'}}
                        />
                    </div>
                    <div className="w-full md:w-[410px]">
                        <PrimaryButton
                            text="Copy to clipboard"
                            onClick={handleCopyToClipboard}
                            type="button"
                            bgColor={'bg-white'}
                            textColor={'text-gray-400'}
                            className={"w-2/3"}
                        />
                    </div>
                </div>
            </div>
        </SidebarLayout>

    );
}

export default Rag;