import { XMarkIcon } from '@heroicons/react/24/outline';
import { AuthContext } from "../../store/AuthContext";
import { useContext } from "react";
import SidebarItem from './SidebarItem';

const MobileSidebar = ({ menuVisible, setMenuVisible }) => {
    const authContext = useContext(AuthContext);
    const isActive = (link) => {
        const activePath = window.location.pathname.split('/')[1];
        return link && activePath === link.replace('/', '');
    }

    return (
        <div className={`fixed top-0 right-0 w-4/5 h-full bg-dinaric-teal z-[15] transition-transform transform ${menuVisible ? 'translate-x-0' : 'translate-x-full'} ease-in-out duration-300`}>
            <div className="flex items-center justify-between px-6 py-4 border-b border-gray-400 shadow-sm">
                <h2 className="text-3xl text-white font-normal min-w-min">Menu</h2>
                <XMarkIcon
                    className="cursor-pointer"
                    width={32}
                    color="white"
                    onClick={() => setMenuVisible(false)}
                />
            </div>
            <div className="flex flex-col items-start px-6 mt-10 space-y-6 font-sans font-normal">
                <SidebarItem
                        title="Dashboard"
                        link="/dashboard"
                        isActive={isActive("/dashboard")}
                    />
                <SidebarItem
                        title="RAG"
                        link="/rag"
                        isActive={isActive("/rag")}
                    />
                <SidebarItem
                        title="Legal Assistant"
                        link="/legal-assistant"
                        isActive={isActive("/legal-assistant")}
                    />
                <SidebarItem
                        title="Logout"
                        onClick={authContext.logout}
                    />
            </div>
        </div>
    );
};

export default MobileSidebar;