import { Bars3Icon } from "@heroicons/react/24/outline";
import { useState } from "react";
import Sidebar from "../sidebar/Sidebar";
import MobileSidebar from "../sidebar/MobileSidebar";

const SidebarLayout = ({ children, title, containerClass }) => {
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

    return (
        <div className="w-screen h-screen mt-5 overflow-x-hidden overflow-y-hidden bg-primary">
            {mobileMenuVisible &&
                <div onClick={() => setMobileMenuVisible(false)} className="absolute w-full h-full bg-black z-[11] bg-opacity-50 backdrop-blur-sm"></div>
            }
            <Sidebar />
            <MobileSidebar menuVisible={mobileMenuVisible} setMenuVisible={setMobileMenuVisible} />
            <div className={"h-full md:ml-[320px] pb-20"}>
                <div className="relative flex items-center justify-between h-20 w-full px-6 space-x-4 bg-white drop-shadow z-[8]">
                    <div className="p-5 w-full bg-dinaric-codeblock rounded-lg">
                        <h2 className="text-3xl font-semibold min-w-min">{title}</h2>
                    </div>
                    <Bars3Icon
                        onClick={() => setMobileMenuVisible(true)}
                        width={32}
                        height={32}
                        className="cursor-pointer md:hidden"
                    />
                </div>
                <div className="h-full">
                    <div className={`${containerClass ? containerClass : undefined} h-full px-6 py-8 overflow-y-auto md:p-8`}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SidebarLayout;