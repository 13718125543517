import React from 'react';

export default function ElevationLogo() {
  return (
    <div className="flex items-center ">
      <svg width="62" height="65" viewBox="0 0 72 75" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M23.0662 22.4031H71.7143V0.352539H0.958706V22.4031H16.5045L23.0662 0.402133V22.4031ZM15.888 24.4701H0.77403V49.2958H8.48382L15.888 24.4701ZM23.0662 49.2958V24.4701H71.7041V49.2958H42.1109L32.7831 28.2288V49.2958H23.0662ZM6.85031 54.7728H0.352539V74.6477H0.922686L6.85031 54.7728ZM23.0662 74.6477V54.7728H32.7831V74.6477H23.0662ZM53.3361 74.6477H57.3368V54.7728H44.536L53.3361 74.6477ZM57.5587 54.7728L70.7694 74.6477H71.4589V54.7728H57.5587Z" fill="white"/>
      </svg>
      <h1 className="ml-2 text-3xl font-normal text-white">Elevation</h1>
    </div>
  );
}