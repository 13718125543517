import { createContext, useState } from "react";

export const

    SidebarMenuContext = createContext({
        dashboardShowSubItems: false,
    });

const SidebarMenuContextProvider = ({ children }) => {
    const [dashboardShowSubItems, setDashboardShowSubItems] = useState();

    const value = {
        dashboardShowSubItems: dashboardShowSubItems,
        setDashboardShowSubItems: setDashboardShowSubItems,
    };

    return (
        <SidebarMenuContext.Provider value={value}>{children}</SidebarMenuContext.Provider>
    );
}

export default SidebarMenuContextProvider;