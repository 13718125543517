// import './App.css';
import './globals.css'
import { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LogIn from "./pages/Auth/LogIn";
import 'react-perfect-scrollbar/dist/css/styles.css';
import { AuthContext } from "./store/AuthContext";
import ProtectedRoute from "./shared/ProtectedRoute";
import { getToken } from "./shared/util";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Dashboard from "./pages/Dashboard/Dashboard";
import SidebarMenuContextProvider from './store/SidebarMenuContext';
import Rag from './pages/Rag/Rag';
import LegalAssistant from './pages/LegalAssistant/LegalAssistant';

function App() {
  const [loading, setLoading] = useState(true);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const token = getToken();

    if (!token) {
      authContext.logout();
    }
    if (!authContext.session && token) {
      authContext.login(token)
    } else {
      setLoading(false);
    }

  }, []);

  const getDefaultRoute = () => {
    if (!authContext.session) {
      return <LogIn />;
    }
    return <Dashboard />;
  }

  return (
    <SidebarMenuContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={authContext.session ?
            <ProtectedRoute>
              {getDefaultRoute()}
            </ProtectedRoute> :
            <LogIn />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/dashboard" element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } />
          <Route path="/rag" element={
            <ProtectedRoute>
              <Rag />
            </ProtectedRoute>
          } />
          <Route path="/legal-assistant" element={
            <ProtectedRoute>
              <LegalAssistant />
            </ProtectedRoute>
          } />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </SidebarMenuContextProvider>
  );
}

export default App;
