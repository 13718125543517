import SidebarItem from "./SidebarItem";
import ProtectedElement from "../../shared/ProtectedElement";
import { AuthContext } from "../../store/AuthContext";
import { useContext } from "react";
import { SidebarMenuContext } from "../../store/SidebarMenuContext";
import ElevationLogo from '../icons/ElevationLogo';

const Sidebar = ({ menuVisible, setMenuVisible }) => {

    const isActive = (link) => {
        const activePath = window.location.pathname.split('/')[1];
        return link && activePath === link.replace('/', '');
    }

    const authContext = useContext(AuthContext);
    const sidebarMenuContext = useContext(SidebarMenuContext);

    return (
        <div className={`invisible md:visible ml-3 my-5 rounded-xl absolute top-0 bottom-0 lg:left-0 w-[78%] md:w-[320px] overflow-y-auto text-center bg-dinaric-teal z-[14] ease-in-out duration-300 md:translate-x-0 ${true ? 'translate-x-0' : '-translate-x-full invisible'}`}>
            <div className="flex items-center w-full p-3 bg-dinaric-teal">
                <ElevationLogo />
            </div>
            <div className="flex flex-col mt-10 px-3 space-y-1">
                <ProtectedElement>
                    <SidebarItem
                        title="Dashboard"
                        link="/dashboard"
                        showSubitems={sidebarMenuContext.dashboardShowSubItems}
                        setShowSubitems={sidebarMenuContext.setDashboardShowSubItems}
                        isActive={isActive("/dashboard")}
                    />
                </ProtectedElement>
                <ProtectedElement>
                    <SidebarItem
                            title="RAG"
                            link="/rag"
                            isActive={isActive("/rag")}
                        />
                </ProtectedElement>
                <ProtectedElement>
                    <SidebarItem
                            title="Legal Assistant"
                            link="/legal-assistant"
                            isActive={isActive("/legal-assistant")}
                    />
                </ProtectedElement>
                <ProtectedElement>
                    <SidebarItem
                        title="Logout"
                        onClick={authContext.logout}
                    />
                </ProtectedElement>
            </div>
        </div>
    );
}

export default Sidebar;