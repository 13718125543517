import SidebarLayout from "../../components/layouts/SidebarLayout";

const Dashboard = () => {
    return (
        <SidebarLayout title="Dashboard">
            <div className="flex flex-col mb-4 space-y-3 md:space-y-0 md:space-x-4 md:flex-row">Welcome To Elevation Dashboard</div>
        </SidebarLayout>
    );
}

export default Dashboard;