import { ApiCore } from "../utilities/core";

const sessionApi = () => {
    return new ApiCore({
        getAll: true,
        getSingle: true,
        post: true,
        put: true,
        patch: false,
        remove: true,
        url: 'session'
    });
}

export default sessionApi;