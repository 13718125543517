export const isValid = (fields) => {
    return !fields.some(x => !x.isValid);
}

export const getFirstError = (fieldConfig) => {
    const firstError = fieldConfig.validators ? fieldConfig.validators.find(x => !x.isValid) : null;
    return firstError ? firstError.message : "";
}

export const changeField = (event, fieldConfig, compareFieldConfig) => {
    fieldConfig.value = event.target.value;
    let isValid = true;

    if (!fieldConfig.validators) {
        return fieldConfig;
    }

    fieldConfig.validators.forEach(validator => {
        validator.isValid = validateField(validator.type, event.target.value, compareFieldConfig ? compareFieldConfig.value : null);
        if (!validator.isValid) {
            isValid = false;
        }
    });

    fieldConfig.isValid = isValid;
    if (!event.target.value && isValid) {
        fieldConfig.showValidation = false;
    }

    return fieldConfig;
}

export const getErrorForChain = (value, chain) => {
    let error = null;

    for (let i = 0; i <= chain.length; i++) {
        const element = chain[i];
        if (!element) continue;

        const isValid = validateField(element.type, value);

        if (!isValid) {
            error = element.message;
            break;
        }
    }

    return error;
}

export const validateField = (type, value, compareValue) => {
    switch (type) {
        case "required": {
            return !!value;
        }
        case "email": {
            return isEmail(value);
        }
        case "password": {
            return isCorrectPassword(value);
        }
        case "same_as": {
            return value === compareValue;
        }
        default: {
            return false;
        }
    }
}

export const isEmail = (email) => {
    return !!String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

export const isCorrectPassword = (password) => {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(password);
}
