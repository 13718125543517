import { apiProvider } from './provider';

const generateUrl = (options) => {
    return options.url;
}

export class ApiCore {
    constructor(options) {
        if (options.getAll) {
            this.getAll = (model, config) => {
                return apiProvider.getAll(generateUrl(options), model, config);
            };
        }

        if (options.getSingle) {
            this.getSingle = (id, config) => {
                return apiProvider.getSingle(generateUrl(options), id, config);
            };
        }

        if (options.post) {
            this.post = (model, config, id) => {
                return apiProvider.post(generateUrl(options), model, config, id);
            };
        }

        if (options.put) {
            this.put = (id, model, config) => {
                return apiProvider.put(generateUrl(options), id, model, config);
            };
        }

        if (options.patch) {
            this.patch = (id, model, config) => {
                return apiProvider.patch(generateUrl(options), id, model, config);
            };
        }

        if (options.remove) {
            this.remove = (id) => {
                return apiProvider.remove(generateUrl(options), id);
            };
        }
    }
}