import { ApiCore } from "../utilities/core";

const legalAssistantApi = () => {
    return new ApiCore({
        getAll: false,
        getSingle: false,
        post: true,
        put: false,
        patch: false,
        remove: false,
        url: 'legal-assistant'
    });
}

export default legalAssistantApi;