import Spinner from "../icons/Spinner";
import { Link } from "react-router-dom";

const PrimaryButton = ({ text = 'Button', spinning = false, type = 'button', bgColor = '', textColor = 'text-white', icon, onClick, to, disabled, className }) => {
    const getButton = () => {
        return (
            <button
                type={type}
                disabled={disabled}
                onClick={onClick}
                className={`flex items-center justify-center w-full px-4 py-3 space-x-3 font-sans font-medium ${textColor || 'text-white'} cursor-pointer ${bgColor || 'bg-dinaric-button'} rounded-2xl active:drop-shadow-none drop-shadow disabled:opacity-75 disabled:cursor-default ${!disabled && 'hover:opacity-75'}`}
            >
                <div>{text}</div>
                {icon && !spinning && icon}
                {spinning && <Spinner />}
            </button>
        );
    };

    return (
        <div className={className}>
            {onClick ? getButton() : <Link to={to}>{getButton()}</Link>}
        </div>
    );
};

export default PrimaryButton;